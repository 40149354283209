<template>
    <section class="contact-section bg-gray" id="contactUs">
        <div class="container">
            <div class="row">
                <div class="contact-card">
                    <div class="underTitle">Need help?</div>
                    <div class="title-h2 mb-56">Send us a Message</div>
                    <div class="d-flex d-block-mobile">
                        <div class="flex-grow-1 mr-24 mr-m-0">
                            <input :maxlength="16" type="text" placeholder="First Name" v-model="firstName" @keypress="isLetter" />
                        </div>
                        <div class="flex-grow-1">
                            <input :maxlength="16" type="text" placeholder="Last Name" v-model="secondName" @keypress="isLetter" />
                        </div>
                    </div>
                    <div>
                        <input type="text" placeholder="Email" v-model="email" />
                    </div>
                    <div>
                        <textarea placeholder="Enter Your Message..." class="mb-56" v-model="message">
                            Enter Your Message...
                        </textarea>
                    </div>
                    <div v-if="errors" class="mb-24 text-danger">
                        {{ errors }}
                    </div>
                    <div v-if="success" class="mb-24 text-success">
                        {{ success }}
                    </div>
                    <div class="text-t-center">
                        <button v-on:click="() => checkForm()" class="btn-indigo">Submit Now</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="ecipse-bg-2"></div>
    </section>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            firstName: '',
            secondName: '',
            email: '',
            message: '',

            success: '',
            errors: '',
        }
    },
    methods: {
        isValidEmail(email) {
            console.log(/^[^@]+@\w+(\.\w+)+\w$/.test(email) ? true : false)
            return /^[^@]+@\w+(\.\w+)+\w$/.test(email) ? true : false
        },
        checkForm() {
            if (this.firstName.length < 2) {
                this.errors = 'First Name required.'
                return false
            }
            if (this.secondName.length < 2) {
                this.errors = 'Last name required.'
                return false
            }
            if (this.email.length < 2) {
                this.errors = 'Email required.'
                return false
            }
            if (this.isValidEmail(this.email) === false) {
                this.errors = 'Email invalid.'
                return false
            }
            if (this.message.length < 2) {
                this.errors = 'Message required.'
                return false
            }

            this.sendMessage()
        },
        sendMessage() {
            const headers = { Accept: 'text/plain', 'Content-Type': 'text/plain' }

            axios
                .post(
                    'https://mattermost.denovolab.com/hooks/fx3oo6dqsfn3mef8fekd89fb9e',
                    {
                        text: `Freefone.app contact \n firstName: ${this.firstName} \n secondName: ${this.secondName} \n email: ${this.email} \n message: ${this.message}`,
                        channel: '#website-contact-form',
                        username: 'Peeringhub Subscriber',
                    },
                    headers
                )
                .then(() => {
                    this.firstName = ''
                    this.secondName = ''
                    this.email = ''
                    this.message = ''
                    ;(this.success = 'The message was sent successfully'), (this.errors = '')
                })
                .catch((error) => {
                    alert(error, error.response)
                })
        },
        isLetter (e) {
            if (!/^[A-Za-z-.']+$/.test(e.key)) return e.preventDefault()
        }
    },
}
</script>

<style>
@import './../../../assets/styles/components/sections/HomePage/10_SendUsMessageSection.css';
</style>
