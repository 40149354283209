import { createApp } from 'vue'
import App from './App.vue'
import router from './routers'
import store from './store/file'
import http from './config/axios'
var VueScrollTo = require('vue-scrollto')

const app = createApp(App)
app.config.globalProperties.$http = http
app.use(router)
app.use(store)
app.use(VueScrollTo)
app.mount('#app')
