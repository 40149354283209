<template>
    <section class="compare-section bg-gray" id="compare-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-7 col-xs-12">
                    <div class="underTitle text-t-left">Pricing</div>
                    <div class="title-h2 mb-64 text-t-left">Compare & get your plan</div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-5 col-xs-12 text-m-center">
                    <div class="borderGray mt-21 d-inline-block compare-section--block">
                        <div class="login-register-block-radios form-group mb-31">
                            <input
                                type="radio"
                                class="btn-check"
                                name="options-outlined"
                                id="success-outlined"
                                autocomplete="off"
                                checked
                            />
                            <label
                                @click="() => changePeriod(true)"
                                class="btn btn-gray text-center bg-gray"
                                for="success-outlined"
                            >
                                Monthly
                            </label>
                            <input
                                type="radio"
                                class="btn-check"
                                name="options-outlined"
                                id="dark-outlined"
                                autocomplete="off"
                            />
                            <label
                                @click="() => changePeriod(false)"
                                class="btn btn-gray text-center bg-gray"
                                for="dark-outlined"
                            >
                                Annually
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="show-only-mobile">
                <div class="mt-38 mb-32">
                    <div class="small-gray-txt mb-16">Free</div>
                    <div class="compare-price mb-12">
                        <div :class="[period == 'monthly' ? '' : 'd-none']">
                            $0<span class="small-gray-txt">/mo</span>
                        </div>
                        <div :class="[period == 'monthly' ? 'd-none' : '']">
                            $0<span class="small-gray-txt">/mo</span>
                        </div>
                    </div>

                    <div class="mb-20">
                        <a class="btn-small-indigo" href="https://portal.freefone.app"> Get Started </a>
                    </div>

                    <div class="d-flex justify-content-between compare-table-td mt-38 border-top-1">
                        <div>Phone Number</div>
                        <div>US local numbers</div>
                    </div>

                    <div class="d-flex justify-content-between compare-table-td">
                        <div>Inbound Call</div>
                        <div class="indigo-color">Unlimited</div>
                    </div>

                    <div class="d-flex justify-content-between compare-table-td">
                        <div>Outbound Call <span class="small-gray-txt">(CAN and US)</span></div>
                        <div>100 min</div>
                    </div>

                    <div class="d-flex justify-content-between compare-table-td">
                        <div>Inbound SMS</div>
                        <div class="indigo-color">Unlimited</div>
                    </div>

                    <div class="d-flex justify-content-between compare-table-td">
                        <div>Outbound SMS</div>
                        <div>Not Supported</div>
                    </div>
                </div>
                <div class="mb-32">
                    <div class="small-gray-txt mb-16">Pro</div>

                    <div class="compare-price mb-12">
                        <div :class="[period == 'monthly' ? '' : 'd-none']">
                            $4.99<span class="small-gray-txt">/mo</span>
                        </div>
                        <div :class="[period == 'monthly' ? 'd-none' : '']">
                            $3.99<span class="small-gray-txt">/mo</span>
                        </div>
                    </div>

                    <div class="mb-20">
                        <a class="btn-small-indigo" href="https://portal.freefone.app"> Get Started </a>
                    </div>

                    <div class="d-flex justify-content-between compare-table-td mt-38 border-top-1">
                        <div>Phone Number</div>
                        <div>US and Canada local numbers</div>
                    </div>

                    <div class="d-flex justify-content-between compare-table-td">
                        <div>Inbound Call</div>
                        <div class="indigo-color">Unlimited</div>
                    </div>

                    <div class="d-flex justify-content-between compare-table-td">
                        <div>Outbound Call <span class="small-gray-txt">(CAN and US)</span></div>
                        <div class="indigo-color">Unlimited</div>
                    </div>

                    <div class="d-flex justify-content-between compare-table-td">
                        <div>Inbound SMS</div>
                        <div class="indigo-color">Unlimited</div>
                    </div>

                    <div class="d-flex justify-content-between compare-table-td">
                        <div>Outbound SMS</div>
                        <div class="indigo-color">Unlimited</div>
                    </div>
                </div>
            </div>
            <div class="row hide-mobile">
                <div class="table-responsive">
                    <table class="compare-table">
                        <tr>
                            <td></td>
                            <td>
                                <div class="small-gray-txt mb-16">Free</div>
                                <div class="compare-price mb-16">
                                    <div v-if="period === 'monthly'">
                                        ${{ freePlan.plan_monthly_price }}
                                        <span class="small-gray-txt">/month</span>
                                    </div>
                                    <div v-else>
                                        ${{ freePlan.plan_yearly_price }}
                                        <span class="small-gray-txt">/year</span>
                                    </div>
                                </div>
                                <div>
                                    <a class="btn-small" href="https://portal.freefone.app"> Get Started </a>
                                </div>
                            </td>
                            <td>
                                <div class="small-gray-txt mb-16">Pro</div>
                                <div class="compare-price mb-16">
                                    <div v-if="period === 'monthly'">
                                        ${{ proPlan.plan_monthly_price }}
                                        <span class="small-gray-txt">/month</span>
                                    </div>
                                    <div v-else>
                                        ${{ proPlan.plan_yearly_price }}
                                        <span class="small-gray-txt">/year</span>
                                    </div>
                                </div>
                                <div>
                                    <a class="btn-small-indigo" href="https://portal.freefone.app"> Get Started </a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Phone Number</td>
                            <td>US local numbers</td>
                            <td>US and Canada local numbers</td>
                        </tr>
                        <tr>
                            <td>Inbound Call</td>
                            <td class="indigo-color">Unlimited</td>
                            <td class="indigo-color">Unlimited</td>
                        </tr>
                        <tr>
                            <td>Outbound Call <span class="small-gray-txt">(CAN and US)</span></td>
                            <td>
                                <ul class="price-ul">
                                    <li>{{ freePlan.free_min_per_day }} min / day</li>
                                    <li>{{ freePlan.free_min_per_month }} min / month</li>
                                </ul>
                            </td>
                            <td class="indigo-color">Unlimited</td>
                        </tr>
                        <tr>
                            <td>Inbound SMS</td>
                            <td class="indigo-color">Unlimited</td>
                            <td class="indigo-color">Unlimited</td>
                        </tr>
                        <tr>
                            <td>Outbound SMS</td>
                            <td>
                                <ul class="price-ul">
                                    <li>{{ freePlan.free_sms_min }} text / min</li>
                                    <li>{{ freePlan.free_sms_hour }} text / hour</li>
                                    <li>{{ freePlan.free_sms_day }} text / day</li>
                                </ul>
                            </td>
                            <td class="indigo-color">Unlimited</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            period: 'monthly',
            planList: [],
            freePlan: {},
            proPlan: {}
        }
    },
    created () {
        this.loadPlans()
    },
    methods: {
        async loadPlans () {
            try {
                const { data } = await this.$http.get('/plan/list')
                if (!data || !data.data) return
                this.planList = data.data
                const free = data.data.find(e => e.plan_name.includes('free'))
                if (free) this.freePlan = free
                const pro = data.data.find(e => e.plan_name.includes('VIP'))
                if (pro) this.proPlan = pro
            } catch (err) {
                throw new Error(err)
            }
        },
        changePeriod(value) {
            value === false ? (this.period = 'anually') : (this.period = 'monthly')
        },
    },
}
</script>

<style>
@import './../../../assets/styles/components/sections/HomePage/06_ComaperPlanSection.css';
</style>
