<template>
    <MainSection />
    <FeaturesSection />
    <ProtectIdentityection />
    <FreeoneProtectSection />
    <PerfectSolutionSection />
    <ComaperPlanSection />
    <WeProvideSection />
    <CountryListSection />
    <FAQSection />
    <SendUsMessageSection />
    <TryItFreeSection />
</template>
<script>
import MainSection from './../sections/HomePage/01_MainSection.vue'
import FeaturesSection from './../sections/HomePage/02_FeaturesSection.vue'
import ProtectIdentityection from './../sections/HomePage/03_ProtectIdentityection.vue'
import FreeoneProtectSection from './../sections/HomePage/04_FreeoneProtectSection.vue'
import PerfectSolutionSection from './../sections/HomePage/05_PerfectSolutionSection.vue'
import ComaperPlanSection from './../sections/HomePage/06_ComaperPlanSection.vue'
import WeProvideSection from './../sections/HomePage/07_WeProvideSection.vue'
import CountryListSection from './../sections/HomePage/08_CountryListSection.vue'
import FAQSection from './../sections/HomePage/09_FAQSection.vue'
import SendUsMessageSection from './../sections/HomePage/10_SendUsMessageSection.vue'
import TryItFreeSection from './../sections/HomePage/11_TryItFreeSection.vue'

export default {
    components: {
        MainSection,
        FeaturesSection,
        ProtectIdentityection,
        FreeoneProtectSection,
        PerfectSolutionSection,
        ComaperPlanSection,
        WeProvideSection,
        CountryListSection,
        FAQSection,
        SendUsMessageSection,
        TryItFreeSection,
    },
    data() {
        return {}
    },
    created () {
        if (!this.$route.params.social) return
        window.location = `${process.env.VUE_APP_TESTPORTALURL}/auth/${this.$route.params.social}?code=${this.$route.query.code}`
    },
    methods: {},
}
</script>
<style></style>
