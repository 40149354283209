<template>
    <section ref="why-us">
        <div class="container freeone-container bg-gray">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12 pr-59 pr-m-0">
                    <div class="underTitle">Who are we</div>
                    <div class="title-h2 mb-32">Freefone safeguards your identity against theft and misuse.</div>
                    <div class="mb-32 text-t-center text-m-center">
                        <img :src="imgage" class="mb-27 img-fluid hide-desktop-only" alt="Freefone safeguards your identity against theft and misuse" />
                        <p>
                            Freefone is your second-phone number app designed to protect your identity from theft or misuse. 
                            It provides a secure space for calling and texting, shielding you from spam or junk calls. With 
                            Freefone, you can organize different types of contacts by using separate phone numbers and easily 
                            switch between them. We prioritize privacy with no contracts, no connection to your personal number, 
                            and a totally privacy-protected experience. Let Freefone handle spam for you - direct all unwanted 
                            calls and messages to your Freefone numbers, and we'll block them. Download Freefone now for hassle-free, private communication.
                        </p>
                    </div>
                    <div class="text-t-center text-m-center">
                        <a class="btn-black" href="https://portal.freefone.app"> Get Started </a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12 text-center hide-tablet hide-mobile">
                    <img :src="imgage" class="img-fluid" alt="Freefone safeguards your identity against theft and misuse" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            imgage: require('./../../../assets/images/components/Sections/FreeoneProtect.svg'),
        }
    },
}
</script>

<style>
@import './../../../assets/styles/components/sections/HomePage/04_FreeoneProtectSection.css';
</style>
