<template>
    <section class="pt-170 pt-m-149 mt-80">
        <div class="container">
            <div class="row">
                <p>
                    If you choose to discontinue Freefone, you can delete your number.
                    You can also port your number out of Freefone or transfer your number to a different Freefone account.
                    Learn how to <a href="https://support.google.com/voice/answer/1065667">port</a>
                    or <a href="https://support.google.com/voice/answer/12083094">transfer</a> your personal number.
                    Important: This feature operates differently based on the type of account you have.
                    <a href="https://support.google.com/voice/answer/9249103">Learn more about Freefone account types.</a>
                </p>
                <h2>Delete your Freefone number</h2>
                <p>Important: After you delete your Freefone number, you may not be able to reclaim it.</p>
                <h2>Process for Deleting your Freefone number</h2>
                <ol>
                    <li>On your browser, go to www.freefone.app.</li>
                    <li>Sign in with the Freefone Account you used to register your number.</li>
                    <li>At the top right, click Settings
                        <img
                        width="19"
                        height="19"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADbUl
                        EQVQ4jW2UX2jVdRjGP8/3/HbcRurmOccdtRwbE50kGWhOS1sFCeFNIEU1iLoPAqEggvIyYt4HUV2MwIrqQiIiCTaMmklk+a/W2XRq2/kzt6LtnJ3f+T1dnMRie67eB97n4X1
                        fXh6xClrb2hgcPEw+vzkiJK0IEkxDrs7cvBmfOX2aanVphS61mtm2/n629fe3izCEfBR4RHDIaMva1nWXl5YW43JpdoVOt4vtO+8lt7ELMOs6OshmsntBH2GtEVSMM0CNhKN
                        zlcq5hT9vYaBUnOHKpQsARADdPb3cv2dPSLe07oak2+Icie4DAvgE6BPQUcFLpNidyWaLmVx2dyJPb+m+53y1upRcnSwQRVFEJpujJZ3ejv0uhB7ZJUS70N8kjANTgnFEDTi
                        OeBXICqbS6fRz2Wzu0o3pa6Q6Ozfw0MOPtgu9IuuA0IjQXxIzmHcw3yCWMRVECbEWNC30LeKAUDrXlR+7NjVZjwxg9ko6gvja5jiQkokw8+BuS30yE9gnQV+B6pYbQBfwBOZ
                        Tw1igaZaz2ZBAe73FHSiesxoV5CGCTiHeJ3AKMZSQlBtO5mopOizWYDKYjTQPDKBR5PcEB9Oxh4XbgF43b9MHzgF9Te5e2e3p2MOYQ4YR4AxAEIBcNJwA/jBsaUiRHHplrUd
                        ISJaRtT4QepNUI0K+G5gh+G3EjO5M5i6hY5hNwtdTdmwlBcsLGGOQwfJCQlIIjVSMdR3cJftlcHNNN90eFH5eeDSJl49hLwEFmbeACYsiMNHkKjhocTnSMaRR0AvAQd9+WlA
                        JNGe56ihaSBQycogwI9hjBPWRMIG4KilLIG5JPC9TA90CygIiAULngC9Az4jUG4K8IG3xmazPZX4F3QU8JfwkUFOiGeTHwCcFPwCk6vVlUiHU812bpjGPCw0CnZge0H7BWeAa
                        YgAxbOgD8oj94Bsmee3Czz/duDo1SRTHMeVSieVa7XIURS86aKvlH2UdFn4daR8wbXgAaEW8aflL7AERCvV6/VK5XCSO4zupsWPHTnIb81iwvrODTDM1PpaVNlTAGaFl8NPlSu
                        n7hYV5gVwqznLl4i//j6D/on/XLvbuG2hPmWcTwvZ/+xzwFTf4cPzsd4sXL5xfoYtWM5ucmKBn69bF/KbNHwi3Nt8xQXZ1tngzLvz+22oy/gFNSpKDs/FL2AAAAABJRU5ErkJgggAA"
                        />
                    .</li>
                    <li>Scroll down to the <a href="https://portal.freefone.app/settings">“Number”</a> section.</li>
                    <li>Click on the <a href="https://portal.freefone.app/settings">“Delete”</a> button.</li>
                </ol>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {}
    },
}
</script>

<style scoped>
@import './../../assets/styles/components/pages/userAgreementPage.css';
p {
    display: block;
}
a {
    color: #0a57d0;
    text-decoration: none;
}
ol {
    margin-left: 40px;
}
</style>
