import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './components/pages/HomePage'
import PrivacyPolicyPage from './components/pages/PrivacyPolicyPage'
import TermsofUsePage from './components/pages/TermsofUsePage'
import LawEnforcementForm from './components/pages/LawEnforcementForm'
import DeleteNumberPage from './components/pages/DeleteNumberPage'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    alias: '/auth/:social?',
  },
   {
    path: '/terms-of-Use',
    component: TermsofUsePage,
  },
  {
    path:'/TermsofUsePage',
    redirect:'/terms-of-Use',
  },
  {
    path: '/privacy',
    component: PrivacyPolicyPage,
  },
  {
    path: '/law-enforcement-form',
    component: LawEnforcementForm,
  },
  {
    path: '/delete_number',
    component: DeleteNumberPage,
  }
 
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView()
    },
})

export default router
