<template>
    <section class="tryitfreeSection pt-m-200 pt-t-200" id="tryforfree">
        <div class="container tryitfreeSection-container">
            <div class="bg-gray tryitfree-card">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12 position-relative text-m-center">
                        <div class="hide-desktop-only tryitfree-image-mobile">
                            <img :src="logo" class="img-fluid tryitfree-image-tablet" alt="Freefone" />
                        </div>
                        <div class="tryitfree-quote"></div>
                        <div class="title-h2 mb-24">Try it for free</div>
                        <div class="mx-w-438 text-t-center">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <ul class="protect-list">
                                        <li>Unlimited calls and texts</li>
                                        <li>Blocking, auto-reply & voicemail</li>
                                    </ul>
                                </div>
                                <div class="flex-grow-1">
                                    <ul class="protect-list">
                                        <li>Pick your own local number</li>
                                        <li>Spam blocking</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex mt-56 try-it-free-block">
                            <div class="mr-16">
                                <a class="btn-indigo" href="https://portal.freefone.app"> Start for Free </a>
                            </div>
                            <div>
                                <a class="btn-ghost" href="#compare-section">Explore Plans</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12 text-center position-relative">
                        <img :src="logo" class="tryitfree-image hide-tablet hide-mobile" alt="Freefone" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            logo: require('./../../../assets/images/components/Sections/Main.svg'),
        }
    },
}
</script>

<style>
@import './../../../assets/styles/components/sections/HomePage/11_TryItFreeSection.css';
</style>
