<template>
  <div class="form-wrapper">
    <div class="form">
      <div class="header">
        <img src="../../assets/images/layouts/Header/logo.png" alt="logo">
        <h1>Law Enforcement Request</h1>
      </div>
      <div class="body">
        <p>Freefone will share end-user information with law enforcement only when presented with a valid legal request consisting of a subpoena,
          warrant, or other court order.  We disclose such records in accordance with our privacy policy and applicable law.</p>
        <p>Our process is completely digital.  Please fill out the following form to initiate the process.</p>
        <div class="input">
          <label for="email" class="required">Email</label>
          <input v-model="email" type="email" id="email">
        </div>
        <div class="input long">
          <label for="firstName" class="required">Your Name</label>
          <div class="flex">
            <div>
              <input v-model="firstName" type="text" id="firstName">
              <span class="thin">First Name</span>
            </div>
            <div>
              <input v-model="lastName" type="text" id="lastName">
              <span class="thin">Last Name</span>
            </div>
          </div>
        </div>
        <div class="input">
          <label for="lawAgency">The name of your law enforcement agency</label>
          <input v-model="lawAgency" type="text" id="lawAgency">
        </div>
        <div class="input">
          <label for="phone">Your Phone Number</label>
          <input v-model="phone" type="text" id="phone" @keypress="IsNumber">
        </div>
        <div class="input">
          <label for="requestType" class="required">Type of request</label>
          <select v-model="requestType" id="requestType">
            <option value="">-Select-</option>
            <option value="Subpoena">Subpoena</option>
            <option value="Search warrant">Search warrant</option>
            <option value="Emergency request (Life or death, imminent danger only)">Emergency request (Life or death, imminent danger only)</option>
          </select>
        </div>
        <div class="input">
          <label for="reference" class="required">Reference</label>
          <input v-model="reference" type="text" id="reference">
          <span class="thin">Court case ID, warrant number, subpoena reference, etc.</span>
        </div>
        <div class="input">
          <label for="offendingPhone" class="required">Offending Phone Number</label>
          <input v-model="offendingPhone" type="text" id="offendingPhone" @keypress="IsNumber">
          <span class="thin">This is the phone number you request information from, in E.164 format. (https://en.wikipedia.org/wiki/E.164).
            Freefone can only search user accounts by phone number</span>
        </div>
        <div class="input">
          <label for="dateTime" class="required">Date-Time</label>
          <input v-model="dateTime" type="date" id="dateTime">
          <span class="thin">dd/mm/yyyy HH:MM</span>
          <span class="thin">Freefone users can change their phone numbers. Providing a specific time will simply allow us to pinpoint the correct user.
            If you want to give us a range instead, use this field as Start Date, and the next one as End Date.</span>
        </div>
        <div class="input">
          <label for="endDate">End date</label>
          <input v-model="endDate" type="date" id="endDate">
          <span class="thin">dd/mm/yyyy HH:MM</span>
        </div>
        <div class="input">
          <label for="notes">Notes</label>
          <textarea v-model="notes" id="notes" rows="5"></textarea>
          <span class="thin">Please add any information that you think could be of interest of your request.</span>
        </div>
        <div class="input">
          <label for="supportingDocuments">Supporting Documents</label>
          <div class="choose-files"
            @drop.prevent="onDropFile($event)"
            @dragover.prevent
            @click="onClickFile"
          >
            <div v-if="!uploadedFiles.length" class="no-files">
              <p>Choose File(s)</p>
              <img src="../../assets/images/components/Sections/upload.svg" alt="upload">
            </div>
            <div v-else class="file-list">
              <div v-for="(item, index) in uploadedFiles" :key="index" class="uploadItem">
                <p class="name">{{ item.name }}</p>
                <span class="close" @click.stop="removeFile(index)">x</span>
              </div>
            </div>
            <input ref="onClickFile" id="supportingDocuments" type="file" style="display:none" @change="onClickFileLoad">
          </div>
          <span class="thin">Appropriate legal documentation must be provided. Please note that you must add a document
            if your request relates to a subpoena or search warrant.</span>
        </div>
        <p class="bold">What information can Freefone provide?</p>
        <p>Users can register for Freefone without a verified email or phone number, so any information that is provided may be fictitious.
          However, in the event that an email address or phone number is verified by the user, it will be noted in the records provided.</p>
        <p>Call logs & location information, are included in user registration records. Location information is dependent on the user's device settings.
          GPS data can not be provided. Text message content can also be requested with a search warrant signed by a judge.</p>
        <p>Other possible data includes but is not limited to device, device OS, WiFi/mobile connectivity, and carrier.</p>
        <p class="bold">Retention policy</p>
        <p>The time in which records are kept can vary, and users can request that their data be deleted. Generally speaking,
          records older than 3-6 months may no longer be kept.</p>
      </div>
      <div class="submit-wrapper">
        <button :disabled="disabled" @click="submit">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data: () => ({
    email: '',
    firstName: '',
    lastName: '',
    lawAgency: '',
    phone: '',
    requestType: '',
    reference: '',
    offendingPhone: '',
    dateTime: '',
    endDate: '',
    notes: '',
    uploadedFiles: [],
  }),
  computed: {
    disabled () {
      return !this.email || !this.firstName || !this.lastName || !this.requestType || !this.reference || !this.offendingPhone || !this.dateTime
    }
  },
  methods: {
    async submit () {
      if (this.disabled) return
      try {
        const headers = { Accept: 'text/plain', 'Content-Type': 'text/plain' }
        const { data } = await axios
          .post(
            'https://mattermost.denovolab.com/hooks/6w1zfr6gntymb8zskdh9pj3s9a',
            {
              text: `Email: ${this.email} \n\
First name: ${this.firstName} \n\
Last name: ${this.lastName} \n\
${this.lawAgency ? `The name of your law enforcement agency: ${this.lawAgency} \n` : ''}\
${this.phone ? `Your Phone Number: ${this.phone} \n` : ''}\
Type of request: ${this.requestType} \n\
Reference: ${this.reference} \n\
Offending Phone Number: ${this.offendingPhone} \n\
Date-Time: ${this.dateTime} \n\
${this.endDate ? `End date: ${this.endDate} \n` : ''}\
${this.notes ? `Notes: ${this.notes}` : ''}`,
              channel: '#website-contact-form',
              username: 'Law Enforcement Request',
            },
            headers
          )
        if (!data) return
        this.email = ''
        this.firstName = ''
        this.lastName = ''
        this.lawAgency = ''
        this.phone = ''
        this.requestType = ''
        this.reference = ''
        this.offendingPhone = ''
        this.dateTime = ''
        this.endDate = ''
        this.notes = ''
        this.uploadedFiles = []
      } catch (error) {
        alert(error, error.response)
        console.log(error)
      }
    },
    onDropFile (event) {
      Array.from(event.dataTransfer.files).forEach(element => this.uploadedFiles.push(element))
    },
    removeFile (index) {
      this.uploadedFiles.splice(index, 1)
    },
    onClickFile () {
      this.$refs.onClickFile.click()
    },
    onClickFileLoad (event) {
      Array.from(event.target.files).forEach(element => this.uploadedFiles.push(element))
      this.$refs.onClickFile.value = ''
    },
    IsNumber (event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
    }
  }
}
</script>

<style scoped>
.flex {
  display: flex;
  align-items: center;
}
.form-wrapper {
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f7fd;
}
.form {
  max-width: 798px;
  border: 1px solid #7777;
  background: #fff;
}
.header {
  border-bottom: 1px solid #7777;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
}
h1 {
  font-size: 40px;
  font-weight: 700;
  color: #4338ca;
  margin: 0 auto;
}
.header img {
  max-width: 9%;
}
.body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 20px;
}
.input {
  display: flex;
  flex-direction: column;
}
.input.long {
  width: min(599px, 100%);
}
.input > div.flex {
  gap: 20px;
}
.input > div.flex > div {
  display: flex;
  flex-direction: column;
  width: 50%;
}
textarea,
select,
input {
  height: 30px;
  width: min(399px, 100%);
  border: 1px solid #7777;
  outline: none !important;
  border-radius: 3px;
  margin: 5px 0;
}
textarea {
  width: 100% !important;
  height: unset !important;
}
p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #000;
  margin: 0;
  padding: 0;
}
label {
  font-size: 13px;
  font-weight: 700;
  color: #000;
}
label.required::after {
  content: '*';
  color: red;
  margin-left: 3px;
}
span.thin {
  font-style: italic;
  color: #777;
  font-size: 12px;
}
.uploadItem {
  display: flex;
  align-items: center;
  gap: 10px;
}
span.close {
  font-size: 16px;
  color: #000;
  cursor: pointer;
  line-height: 1;
  border-radius: 50%;
  display: grid;
  place-items: center;
  width: 20px;
  height: 20px;
  padding-bottom: 3px;
  background: #7777;
}
.choose-files {
  border: 1px solid #7777;
  margin: 5px 0;
  border-radius: 3px;
}
.choose-files:hover {
  outline: 3px dashed #777;
  border-color: #fff0;
}
.no-files {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.no-files img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #7773;
  padding: 5px;
}
.file-list {
  padding: 20px;
}
.submit-wrapper {
  width: 100%;
  padding: 10px;
  display: grid;
  place-items: center;
}
.submit-wrapper button {
  background: #4338ca;
  border: none;
  color: #fff;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 1;
}
button[disabled] {
  background: #7777;
}
</style>
