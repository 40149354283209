<template>
    <div>
        <img :src="HamburgerLogo" alt="" class="navbar-icon-hamburger" @click="toggleNavbar()" />
        <div class="navbar-block" v-if="showNavbar">
            <div>
                <img class="navbar-block--image" :src="logo" alt="" />
            </div>
            <div class="navbar-block-closeIcon">
                <img :src="iconClose" alt="" @click="toggleNavbar()" />
            </div>
            <div class="text-center mt-60">
                <ul class="navbar-block-menu">
                    <li>
                        <a class="navbar-block-menu-link" @click="redirect('protectIdentity-section')">
                            Why us
                        </a>
                    </li>
                    <li>
                        <a class="navbar-block-menu-link" @click="redirect('compare-section')">
                            Pricing
                        </a>
                    </li>
                    <li>
                        <a class="navbar-block-menu-link" @click="redirect('protectIdentity-section')">
                            Virtual Phone System
                        </a>
                    </li>
                    <li>
                        <a class="navbar-block-menu-link" @click="redirect('faq')"> FAQ </a>
                    </li>
                    <li>
                        <a class="navbar-block-menu-link" @click="redirect('provide-section')">
                            Our Service
                        </a>
                    </li>
                </ul>
            </div>
            <div class="position-relative">
                <div class="text-center navbar-btn-block">
                    <a href="https://portal.freefone.app" class="btn-indigo">
                        Get Started
                    </a>
                </div>
            </div>
        </div>
        <div @click="toggleNavbar()" class="position-absolute black-bg" v-if="showNavbar"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showNavbar: false,
            iconClose: require('../../assets/images/components/Navbar/Close-icon.svg'),
            HamburgerLogo: require('./../../assets/images/components/Navbar/Hamburger-Black.svg'),
            logo: require('./../../assets/images/layouts/Header/logo.svg'),
        }
    },
    methods: {
        toggleNavbar () {
            this.showNavbar = !this.showNavbar
        },
        redirect (section) {
            this.showNavbar = false
            this.$emit('redirect', section)
        }
    }
}
</script>

<style>
@import '../../assets/styles/components/helpers/Navbar.css';
</style>
